<template>
  <div
    class="
      f6
      h-100
      material-icons-loaded
      bg-concord-near-white
      concord-dark-gray
      fw4
      h-100
      mh0
      pa0
      roboto
      ember-application
      application
      pace-done
    "
    style="height: 100vh"
  >
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div style="flex: 1; display: flex; flex-direction: column" class="pr4">
          <MetaRightSideHeader>
            <template #title>编辑团队</template>
          </MetaRightSideHeader>

          <el-container
            style="padding: 0 20px; background: white; padding-top: 15px"
            class="page-content custom-scroller"
          >
            <div class="userContainer">

              <div class="info-container">
                <span class="info-container-title">团队名称</span>

                <div class="info-container-rightPart">
                  <input
                      class="main-info-input"
                      type="text"
                      disabled="true"
                      v-model="teamName"
                  />
                </div>

              </div>


              <div class="info-container" id="editTagsContainer">
                <div class="info-container-title">
                  团队主题信息关键词
                </div>

                <div class="info-container-rightPart">
                  <div
                      class="index-font add-key"
                      v-show="!tagEditMode && ownKeyWord.length == 0"
                      @click="tagEditMode = true"
                  >
                    +添加关键词
                  </div>

                  <div class="conclusive-firstArea-tags" style="width: 30rem">
                    <div v-if="tagEditMode">
                      <div class="tags" style="min-height: 100px">
                        <el-tag
                            v-for="(item, index) in ownKeyWord"
                            :key="index"
                            class="contract-tag f5"
                            closable
                            size="small"
                            @close="removeTag(item.id, index)"
                        >{{ item.name }}
                        </el-tag>
                        <el-input
                            v-model="inputTag"
                            :input-style="{
                        'border-radius': 0,
                        border: '0',
                        background: '#F4F4F4',
                        padding: '0',
                        height: '30px',
                      }"
                            placeholder="请输入标签"
                            style="width: auto; height: 30px; line-height: 30px"
                            @blur="addByInput(inputTag)"
                            @keypress="inputkeyPress"
                        ></el-input>
                      </div>
                      <div style="text-align: right; margin-top: 10px">
                        <el-button
                            size="mini"
                            type="primary"
                            style="width: 4rem"
                            @click="tagEditMode = false"
                        >确定
                        </el-button>
                      </div>
                    </div>
                    <div
                        v-if="!tagEditMode"
                        class="tags"
                        style="background: unset; border: 0;margin-left: 0"
                        @click="tagEditMode = true"
                    >
                      <el-tag
                          v-for="(item, index) in ownKeyWord"
                          :key="index"
                          class="contract-tag gray-tag gray"
                          size="small"
                      >{{ item.name }}
                      </el-tag>
                    </div>
                  </div>
                </div>

              </div>

              <div class="info-container">
                <div class="info-container-title">行业</div>

                <div class="info-container-rightPart">
                  <el-select v-model="industry" placeholder="错误,未选择行业">
                    <el-option
                        v-for="item in industries"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                  </el-select>


                </div>

              </div>


              <div class="info-container">
                <div class="info-container-title">允许不提交最终版本</div>

                <div class="info-container-rightPart">
                  <el-switch
                      style="margin-left: 5px"
                      v-model="finalizedNeedNotCommitNewVersion"
                  />
                </div>

              </div>

              <div class="info-container">
                <div class="info-container-title">
                  <span>合同标识</span>
                  <i
                      class="el-icon-collection-tag"
                      style="font-size: 18px; margin-right: 10px"
                  ></i>
                </div>

                <div class="info-container-rightContent">
                  <el-radio v-model="radio" label="2"
                    ><div style="display: inline-block;">
                      二维码
                    </div></el-radio
                  >
                  <el-radio v-model="radio" label="1"
                    ><div style="display: inline-block;">
                      版本号
                    </div></el-radio
                  >
                  <el-radio v-model="radio" label="0"
                    ><div style="display: inline-block;">
                      不显示标识
                    </div></el-radio
                  >
                </div>
              </div>

              <div style="font-weight: 500;color: #333;padding: 10px 0;font-size: 1rem;min-height: 20px;display: flex;">
                <div class="info-container-title" v-show="radio!=='0'">
                  <span>标识显示策略</span>
                </div>
                <div class="info-container-rightContent" v-show="radio!=='0'">
                  <el-radio v-model="codeRadio" label="1">
                    <div style="display: inline-block;">
                      每页/节显示
                    </div>
                  </el-radio>
                  <el-radio v-model="codeRadio" label="0">
                    <div style="display: inline-block;">
                      首页/节显示
                    </div>
                  </el-radio>
                </div>
              </div>

              <div class="submit-group">
                <el-button
                  class="rect-btn"
                  style="margin-right: 1.25rem"
                  @click="cancel"
                >
                  取消
                </el-button>
                <el-button
                  @click="updateTeam"
                  type="primary"
                >
                  更新
                </el-button>
              </div>
            </div>
          </el-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MetaMessage } from "../util";
import { ElLoading, ElMessage } from "element-plus";
import config from "../config";
import {updateTeamConfig, getTeamInfo, getIndustry} from "../api/api";
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {globalTagClick} from "../assets/js/tag/tag";
import {checkUserBehaviorPermission,BehaviorPermission} from "../components/common/permissionUtil";

export default {
  name: "addTeam",
  data: function () {
    return {
      checkUserBehaviorPermission,
      teamName: "",
      radio: "2",
      codeRadio: "1",
      industry: "",
      inputTag: "",
      system: this.$route.query.system || 0,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      teamInfo: JSON.parse(sessionStorage.getItem("teamInfo")),
      teamId: "",
      metaMessage: new MetaMessage(),
      activeName: "user",
      ownKeyWord: [
          // { name: 12313 }
      ],
      teamsInfo: [],
      tagEditMode: false,
      loadingInstance: null,
      inviteUrlPrefix:
        "我和“meta”的小伙伴都在飞书等你，用这个专属链接加入我们吧！",
      inviteUrl: "",
      finalizedNeedNotCommitNewVersion: false,
      industries: [
        // {
        //   id: "0",
        //   name: "房产",
        // },
        // {
        //   id: "1",
        //   name: "金融",
        // },
        // {
        //   id: "2",
        //   name: "教育",
        // },
      ],
    };
  },
  mounted() {
    if (!this.userInfo || !checkUserBehaviorPermission(this.userInfo.mainSiteTeamId,BehaviorPermission.MANAGE_TEAM_CONFIG)) {
      ElMessage.error("未登录或暂无权限!");
      this.$router.push({
        name: "teamManagement",
      });
      return;
    }
    if (this.$route.query.teamId) {
      this.teamId = this.$route.query.teamId;
      getTeamInfo(this.teamId).then((res) => {
        if (res.data.code == 0) {
          this.teamName = res.data.data.teamName;
          getIndustry().then((_res)=>{
            this.industries = _res.data.data;
            this.industry = res.data.data.industryId;
          })
          if(res.data.data.ownKeyWord){
            this.ownKeyWord = res.data.data.ownKeyWord
                .split(",")
                .map((i) => {
                  return { name: i };
                });
          }else{
            this.ownKeyWord = [];
          }
          this.finalizedNeedNotCommitNewVersion =
            res.data.data.finalizedNeedNotCommitNewVersion;
          this.radio = String(res.data.data.codeDisplayType);
          this.codeRadio = String(res.data.data.contractCodeDisplayTactics);
        }
      });
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    teamNameInput(e) {
      this.teamName = e.target.value;
    },
    teamDesCriptionInput(e) {
      this.teamDesCription = e.target.value;
    },
    addTeamClicked: function () {
      console.log("新增团队 ========== ", this.organizationUsers);
    },
    addTag(tagName) {
      for (let index in this.ownKeyWord) {
        if (this.ownKeyWord[index].name == tagName) {
          ElMessage.error("标签已存在!");
          return false;
        }
      }
      this.ownKeyWord.push({
        name: tagName,
      });
    },
    removeTag(tagId, index) {
      this.ownKeyWord.splice(index, 1);
    },
    addByInput(tagName) {
      if (this.inputTag != "" || /\S/g.test(this.inputTag)) {
        if (this.addTag(tagName) != false) {
          this.inputTag = "";
        }
      }
    },
    inputkeyPress(e) {
      if (this.inputTag != "") {
        if (e.key == "Enter") {
          this.addByInput(this.inputTag);
        }
      }
    },
    cancel() {
      this.$router.push({
        name: "teamManagement",
      });
    },
    updateTeam() {
      if (this.teamName === "" || !/\S/g.test(this.teamName)) {
        ElMessage.error("请输入团队名称");
        return;
      }
      if (this.industry === "") {
        ElMessage.error("请选择行业");
        return;
      }
      let keyWords = this.ownKeyWord
        .map((item) => {
          return item.name;
        })
        .join(",");
      let params = {
        teamId: this.teamId,
        codeDisplayType: this.radio,
        industryId: this.industry,
        finalizedNeedNotCommitNewVersion: this.finalizedNeedNotCommitNewVersion,
        ownKeyWord: keyWords,
        codeDisplayTactics: this.codeRadio,
      };
      globalTagClick('edit-team',params);
      updateTeamConfig(params).then((res) => {
        if(res.data.code==0){
          ElMessage.success("更新成功");
          this.$router.push({
            name: "teamManagement",
          });
        }
      });
    },
    openLoading: function () {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: "el-icon-loading",
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    },
},
  components: {
    ContractSettingLeftMenu,MetaRightSideHeader,
  },
};
</script>

<style scoped>
@import "../assets/css/teamSetting.css";
</style>
